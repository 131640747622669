import axios from "axios";
import xmlParser from "xml-js";
import {
  GET_ORDER_LIST_SUCCESS,
  GET_ORDER_LIST_FAIL,
  GET_ORDER_SUCCESS,
  GET_ORDER_FAIL,
} from "./types";

function formatDate(date) {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Los meses en JavaScript son de 0-11
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

export const get_order_list = (lan,pool,cliente,api_url,empresa) => async (dispatch) => {
  const config = {
    headers: {
      'Client-Name':  empresa.toLowerCase(),
      "Content-Type": "text/xml",
      SOAPAction: "#POST",
    },
    auth: {
      username: `${process.env.REACT_APP_USER_SAGE}`,
      password: `${process.env.REACT_APP_PWD_SAGE}`,
    },
  };

  // Obtener la fecha actual
  const today = new Date();

  // Obtener la fecha de hace 30 días
  const pastDate = new Date();
  pastDate.setDate(today.getDate() - 30);

  // Formatear las fechas
  const desdeFecha = formatDate(pastDate);
  const hastaFecha = formatDate(today);

  const body = `<soapenv:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:wss="http://www.adonix.com/WSS">
    <soapenv:Header/>
    <soapenv:Body>
        <wss:run soapenv:encodingStyle="http://schemas.xmlsoap.org/soap/encoding/">
            <callContext xsi:type="wss:CAdxCallContext">
                <codeLang xsi:type="xsd:string">${lan}</codeLang>
                <poolAlias xsi:type="xsd:string">${pool}</poolAlias>
                <poolId xsi:type="xsd:string">${pool}</poolId>
                <requestConfig xsi:type="xsd:string">adxwss.trace.on=off&amp;adxwss.beautify=true&amp;adxwss.optreturn=JSON</requestConfig>  
            </callContext>    
            <publicName xsi:type="xsd:string">YGPEDIDOS</publicName>
            <inputXml xsi:type="xsd:string">
                <![CDATA[<?xml version="1.0" encoding="UTF-8"?>
                    <PARAM>
                        <GRP ID="GRP1">        
                            <FLD NAME="PCOD_CLIENTE">${cliente}</FLD>
                            <FLD NAME="DESDE_FECHA">${desdeFecha}</FLD>
                            <FLD NAME="HASTA_FECHA">${hastaFecha}</FLD>                 
                        </GRP>
                    </PARAM>
                ]]>
            </inputXml>
        </wss:run>
    </soapenv:Body>
    </soapenv:Envelope>`;

  try {
    // const res = await axios.post(
    //   api_url,
    //   body,
    //   config
    // );
    const res = await axios.post(
      'https://www.infinity3.es/soap-proxy/',
      body,
      config
    );

    // Convertir XML a JSON
    const jsonPayload = xmlParser.xml2json(res.data, {
      compact: true,
      spaces: 4,
    });

    // Parsear el JSON para acceder a PS_TOKEN
    const result = JSON.parse(jsonPayload);
    const psToken =
      result["soapenv:Envelope"]["soapenv:Body"]["wss:runResponse"][
        "runReturn"
      ]["resultXml"]._cdata;

    res.data = JSON.parse(psToken);

    if (res.status === 200) {
      dispatch({
        type: GET_ORDER_LIST_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_ORDER_LIST_FAIL,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_ORDER_LIST_FAIL,
    });
  }
};

export const get_order = (lan,pool,pedido,api_url,empresa) => async (dispatch) => {
  const config = {
    headers: {
      'Client-Name':  empresa.toLowerCase(),
      "Content-Type": "text/xml",
      SOAPAction: "#POST",
    },
    auth: {
      username: `${process.env.REACT_APP_USER_SAGE}`,
      password: `${process.env.REACT_APP_PWD_SAGE}`,
    },
  };

  const body = `<soapenv:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:wss="http://www.adonix.com/WSS">
    <soapenv:Header/>
    <soapenv:Body>
        <wss:run soapenv:encodingStyle="http://schemas.xmlsoap.org/soap/encoding/">
            <callContext xsi:type="wss:CAdxCallContext">
                <codeLang xsi:type="xsd:string">${lan}</codeLang>
                <poolAlias xsi:type="xsd:string">${pool}</poolAlias>
                <poolId xsi:type="xsd:string">${pool}</poolId>
                <requestConfig xsi:type="xsd:string">adxwss.trace.on=off&amp;adxwss.beautify=true&amp;adxwss.optreturn=JSON</requestConfig>  
            </callContext>    
            <publicName xsi:type="xsd:string">YGDETPED</publicName>
            <inputXml xsi:type="xsd:string">
                <![CDATA[<?xml version="1.0" encoding="UTF-8"?>
                    <PARAM>
                        <GRP ID="GRP1">        
                            <FLD NAME="NUM_PEDIDO">${pedido}</FLD>
                            <FLD NAME="IDIOMA"></FLD>               
                        </GRP>
                    </PARAM>
                ]]>
            </inputXml>
        </wss:run>
    </soapenv:Body>
    </soapenv:Envelope>`;

  try {
    // const res = await axios.post(
    //   api_url,
    //   body,
    //   config
    // );
    const res = await axios.post(
      'https://www.infinity3.es/soap-proxy/',
      body,
      config
    );

    // Convertir XML a JSON
    const jsonPayload = xmlParser.xml2json(res.data, {
      compact: true,
      spaces: 4,
    });

    // Parsear el JSON para acceder a PS_TOKEN
    const result = JSON.parse(jsonPayload);
    const psToken =
      result["soapenv:Envelope"]["soapenv:Body"]["wss:runResponse"][
        "runReturn"
      ]["resultXml"]._cdata;

    res.data = JSON.parse(psToken);

    if (res.status === 200) {
      dispatch({
        type: GET_ORDER_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_ORDER_FAIL,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_ORDER_FAIL,
    });
  }
};
