import axios from "axios";
import xmlParser from "xml-js";
import { GET_CONTACT_ADDRESS_SUCCESS, GET_CONTACT_ADDRESS_FAIL } from "./types";

export const get_contact_address = (lan,pool,sage_client,api_url,empresa) => async (dispatch) => {
  const config = {
    headers: {
      'Client-Name':  empresa.toLowerCase(),
      "Content-Type": "text/xml",
      SOAPAction: "#POST",
    },
    auth: {
      username: `${process.env.REACT_APP_USER_SAGE}`,
      password: `${process.env.REACT_APP_PWD_SAGE}`,
    },
  };

  const body = `<soapenv:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:wss="http://www.adonix.com/WSS">
        <soapenv:Header/>
        <soapenv:Body>
            <wss:run soapenv:encodingStyle="http://schemas.xmlsoap.org/soap/encoding/">
                <callContext xsi:type="wss:CAdxCallContext">
                    <codeLang xsi:type="xsd:string">${lan}</codeLang>
                    <poolAlias xsi:type="xsd:string">${pool}</poolAlias>
                    <poolId xsi:type="xsd:string">${pool}</poolId>
                    <requestConfig xsi:type="xsd:string">adxwss.trace.on=off&amp;adxwss.beautify=true&amp;adxwss.optreturn=JSON</requestConfig>  
                </callContext>    
                <publicName xsi:type="xsd:string">YGETDIR</publicName>
                <inputXml xsi:type="xsd:string">
                    <![CDATA[<?xml version="1.0" encoding="UTF-8"?>
                        <PARAM>
                            <GRP ID="GRP1">        
                                <FLD NAME="PCOD_CLIENTE">${sage_client}</FLD>
	                        </GRP>
                        </PARAM>
                    ]]>
                </inputXml>
            </wss:run>
        </soapenv:Body>
        </soapenv:Envelope>`;

  try {
    // const res = await axios.post(
    //   api_url,
    //   body,
    //   config
    // );
    const res = await axios.post(
      'https://www.infinity3.es/soap-proxy/',
      body,
      config
    );
    // Convertir XML a JSON
    const jsonPayload = xmlParser.xml2json(res.data, {
      compact: true,
      spaces: 4,
    });

    // Parsear el JSON para acceder a PS_TOKEN
    const result = JSON.parse(jsonPayload);
    const psToken =
      result["soapenv:Envelope"]["soapenv:Body"]["wss:runResponse"][
        "runReturn"
      ]["resultXml"]._cdata;

    res.data = JSON.parse(psToken);

    if (res.status === 200) {
      dispatch({
        type: GET_CONTACT_ADDRESS_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_CONTACT_ADDRESS_FAIL,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_CONTACT_ADDRESS_FAIL,
    });
  }
};
