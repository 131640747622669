import { useState, useEffect } from 'react';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom';
import OrderDetail from './OrderDetail';
import axios from 'axios';
import xmlParser from 'xml-js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function OrderCardHorizontal({ data, index, api_url, lan, pool,empresa }) {

    const [generatingReport, setGeneratingReport] = useState(false);
    const [activeOrder, setActiveOrder] = useState(null);
    const [dots, setDots] = useState('');
    const [selectedOption, setSelectedOption] = useState('PRINTSOH');

    const handleOpcionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const [popupOpen, setPopupOpen] = useState(false);

    const togglePopup = () => {
        setPopupOpen(!popupOpen);
    };

    // Animación de los puntos suspensivos
    useEffect(() => {
        let intervalId;
        if (generatingReport) {
            intervalId = setInterval(() => {
                setDots(dots => dots.length < 3 ? dots + '.' : '');
            }, 500); // Cambia cada 500ms
        } else {
            setDots('');
        }
        return () => clearInterval(intervalId);
    }, [generatingReport]);

    async function printReport(lan,pool,webService, orderNumber,api_url, empresa) {
        if (webService !== 'PRINTSOH'){return}
        
        const soapBody = `<soapenv:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:wss="http://www.adonix.com/WSS">
                            <soapenv:Header/>
                            <soapenv:Body>
                                <wss:run soapenv:encodingStyle="http://schemas.xmlsoap.org/soap/encoding/">
                                <callContext xsi:type="wss:CAdxCallContext">
                                    <codeLang xsi:type="xsd:string">${lan}</codeLang>
                                    <poolAlias xsi:type="xsd:string">${pool}</poolAlias>
                                    <poolId xsi:type="xsd:string">${pool}</poolId>
                                    <requestConfig xsi:type="xsd:string">adxwss.trace.on=off&amp;adxwss.beautify=true&amp;adxwss.optreturn=JSON</requestConfig>
                                </callContext>
                                <publicName xsi:type="xsd:string">${webService}</publicName>
                                <inputXml xsi:type="xsd:string">
                                <![CDATA[<?xml version="1.0" encoding="UTF-8"?>
                                <PARAM>
                                    <GRP ID="GRP1">        
                                        <FLD NAME="NUM_DOCUMENTO">${orderNumber}</FLD>
                                    </GRP>
                                </PARAM>
                                ]]>
                                </inputXml>
                                </wss:run>
                            </soapenv:Body>
                        </soapenv:Envelope>`;

        const config = {
            headers: {
                'Client-Name':  empresa.toLowerCase(),
                'Content-Type': 'text/xml',
                'SOAPAction': '#POST'
            },
            auth: {
                username: process.env.REACT_APP_USER_SAGE,
                password: process.env.REACT_APP_PWD_SAGE
            }
        };

        setGeneratingReport(true);
        setActiveOrder(orderNumber);

        try {
            // const response = await axios.post(api_url, soapBody, config);
            const response = await axios.post('https://www.infinity3.es/soap-proxy/', soapBody, config);
            // Convertir XML a JSON
            const jsonPayload = xmlParser.xml2json(response.data, { compact: true, spaces: 4 });

            // Parsear el JSON para acceder a PS_TOKEN
            const result = JSON.parse(jsonPayload);
            const psToken = result['soapenv:Envelope']['soapenv:Body']['wss:runResponse']['runReturn']['resultXml']._cdata;

            response.data = JSON.parse(psToken);
            const enlace = response.data.GRP1.ENLACE;

            if (enlace !== '') {
                await descargarPdf(enlace, orderNumber)
            } else {
                toast.error(`Fallo al generar la impresión del informe`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

            return enlace;
        } catch (error) {
            console.error("Error en la solicitud SOAP:", error);
            throw error;
        } finally {
            setGeneratingReport(false);
            setActiveOrder(null);
        }
    }

    async function descargarPdf(url, documento) {
        const config = {
            responseType: 'blob',
            auth: {
                username: process.env.REACT_APP_USER_SAGE,
                password: process.env.REACT_APP_PWD_SAGE
            }
        };

        try {
            const response = await axios.get(url, config);
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', documento + '.pdf');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            console.error("Error al descargar el PDF:", error);
            throw error;
        }
    }


    return (
        <tr key={index}
            className="bg-white border-b dark:border-pink-700">
            <td className="w-4 p-2 md:p-4">
                <div className="flex items-center">
                    <input 
                        id={`checkbox-table-search-${index}`} 
                        name={`checkbox-table-search-${index}`}
                        type="checkbox" 
                        className="w-4 h-4 text-pink-600 bg-gray-100 border-gray-300 rounded focus:ring-pink-600 dark:focus:ring-pink-500 dark:ring-offset-pink-800 focus:ring-2 dark:bg-pink-700 dark:border-pink-600" 
                    />
                    <label htmlFor="checkbox-table-search-1" className="sr-only">checkbox</label>
                </div>
            </td>
            <th scope="row" className="py-4 font-medium text-gray-900 dark:text-pink-400 whitespace-nowrap">
                <div className="flex flex-col text-center">
                    <Link onClick={togglePopup}>
                        <span className='hover:text-pink-600'>{data.NUM_PEDIDO}</span>
                    </Link>
                    <span className="text-gray-500 ">{data.REFERENCIA ? data.REFERENCIA : '**Referencia**'}</span>
                    {/* <span>{data.REFERENCIA}</span> */}
                </div>
            </th>
            <td className="py-4 text-center">
                <div className="flex flex-col">
                    <span><b>Pedido:</b> {data.FECHA_PEDIDO}</span>
                    <span><b>Entrega:</b> {data.FECHA_ENTREGA}</span>
                </div>
            </td>
            <td className="items-start py-4 text-center">
                <div className="flex flex-col">
                    <span>{data.ESTADO}</span>
                </div>
            </td>
            <td className="py-4 text-center">
                <div className="flex flex-col">
                    <span>{data.IMPORTE} €</span>
                </div>
            </td>
            <td className="flex py-4 text-center items-center justify-center">
                <select
                    id={`select-option-${index}`}
                    name={`select-option-${index}`}
                    value={selectedOption}
                    onChange={handleOpcionChange}
                    className='border border-transparent rounded bg-transparent '>
                    <option value="PRINTSOH">Pedido</option>
                    <option value="PRINTSDH">Entrega</option>
                    <option value="PRINTSIH">Factura</option>
                </select>
                <div className="flex items-center">
                    <Link onClick={() => printReport(lan,pool,selectedOption, data.NUM_PEDIDO,api_url,empresa)}>
                        <ArrowDownTrayIcon className='ml-4 h-auto w-6' />
                    </Link>
                    {generatingReport && activeOrder === data.NUM_PEDIDO && (
                        <span className="ml-2 text-gray-500">Generando{dots}</span>
                    )}
                </div>
            </td>

            {/* Pop-up de detalles del pedido */}
            {popupOpen && (
                <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white p-4 rounded-lg">
                        {/* Renderizar los detalles del pedido utilizando el componente OrderDetail */}
                        <OrderDetail num_pedido={data.NUM_PEDIDO} />

                        {/* Botón para cerrar el pop-up */}
                        <div className='flex items-center justify-center mt-4'>
                            <button
                                className='border-1 rounded-xl bg-pink-500 hover:bg-pink-600 text-white p-2 '
                                onClick={togglePopup}
                            >
                                Cerrar
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </tr>
    )
}
export default OrderCardHorizontal