import Layout from "hocs/layouts/Layout"
import { connect } from "react-redux"
import { useEffect, useState } from "react"
import { get_order_list } from "redux/actions/orders/orders"
import OrdersList from "components/orders/OrdersList"

function MisPedidos({
    lan,
    pool,
    get_order_list,
    orders,
    sage_client,
    fondo_empresa,
    api_url,
    empresa,
}) {

    useEffect(() => {
        window.scrollTo(0, 0)
        get_order_list(lan, pool, sage_client,api_url,empresa)
    }, [get_order_list, sage_client])

    const [searchTerm, setSearchTerm] = useState('');
    const searchTermLower = searchTerm.toLowerCase();

    const parseDate = (dateString) => {
        const [day, month, year] = dateString.split('/');
        return new Date(`${year}-${month}-${day}`);
    }

    const filteredOrders = orders ? orders
        .filter(order =>
            order.NUM_PEDIDO.toLowerCase().includes(searchTermLower) ||
            order.FECHA_PEDIDO.toLowerCase().includes(searchTermLower) ||
            order.REFERENCIA.toLowerCase().includes(searchTermLower) ||
            order.ESTADO.toLowerCase().includes(searchTermLower)
        )
        .sort((a, b) => {
            const dateComparison = parseDate(b.FECHA_PEDIDO) - parseDate(a.FECHA_PEDIDO);
            if (dateComparison !== 0) {
                return dateComparison;
            }
            return b.NUM_PEDIDO.localeCompare(a.NUM_PEDIDO, undefined, { numeric: true });
        })
        : [];



    return (
        <Layout>
            <div
                className="fixed w-full grid min-h-screen place-items-center bg-cover "
                style={{ backgroundImage: `url(${fondo_empresa})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
            >
                {/*    <div className="absolute bg-white opacity-70 w-full text-center "></div>*/}
                <div className="w-96 sm:w-11/12 h-auto mx-auto">
                    <div className="relative">
                        <div className="flex p-4">
                            <h1 className="text-pink-500 font-semibold w-1/4 text-left text-4xl ml-2 md:mt-10 xs:mt-12">Mis Pedidos</h1>
                            <div className="relative flex justify-end w-3/4 items-center mr-36 space-x-2 md:mr-4 md:mt-10 xs:mt-12">
                                <div className="relative flex items-center">
                                    <input
                                        id="search-orders"
                                        type="text"
                                        className="bg-pink-100 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full pr-10 p-2.5 dark:bg-pink-100 dark:border-pink-600 dark:placeholder-gray-500"
                                        placeholder="Buscar por fecha/pedido"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        autoComplete="off"
                                    />
                                    <div className="absolute inset-y-0 right-3 flex items-center pl-3 pointer-events-none">
                                        <svg className="w-5 h-5 text-gray-500 dark:text-gray-500" fill="currentColor" viewBox="0 0 20 20">
                                            <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                                        </svg>
                                    </div>
                                </div>
                                {/*<Ripples className="ml-12 middle none center rounded-lg bg-pink-500 py-3 px-6 font-sans text-md font-bold uppercase text-white shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:bg-pink-500 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none">*/}
                                <button
                                    className="ml-1 w-32 rounded-md bg-pink-500 py-1.5 font-medium text-white hover:bg-pink-600"
                                    onClick={() => {
                                        // Acción al hacer clic en el botón
                                    }}
                                >
                                    Repetir pedido
                                </button>
                                {/*</Ripples>*/}


                            </div>
                        </div>
                        <OrdersList orders={filteredOrders && filteredOrders} seachTerm={searchTermLower && searchTermLower} api_url={api_url} lan={lan} pool={pool} empresa={empresa} />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

const mapStateToProps = state => ({
    orders: state.orders.order_list,
    sage_client: state.auth.user?.cliente_sage,
    lan: state.auth.user?.idioma,
    pool: state.auth.user?.default_poolid,
    fondo_empresa: state.auth.empresa.imagen_background,
    api_url: state.auth.empresa?.api_endpoint,
    empresa: state.auth.empresa?.nombre
})

export default connect(mapStateToProps, {
    get_order_list
})(MisPedidos)